import type { NextPage } from 'next'
import Section from 'components/sections/login-register/index'
import Head from 'next/head'

interface Props {}

const Login: NextPage<Props> = () => {
  return (
    <>
      <Head>
        <title>Login - PC4 Management</title>
      </Head>
      <Section />
    </>
  )
}

export default Login
